import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { BlogLayout, Head } from "~/components"
import { IconCalendar, IconArrowRight, IconArrowLeft } from "~/icons"
import * as styles from "~/styles/blog.module.scss"

const Number = ({ page, uri }) => {
  return (
    <Link
      to={page !== 1 ? `/blog${uri}page/${page}` : `/blog${uri}`}
      className={styles.pagination__item}
      activeClassName="is-active"
    >
      {page}
    </Link>
  )
}

const BlogAuthorsArchive = ({
  data,
  pageContext: {
    nextPagePath,
    previousPagePath,
    pageNumber,
    numberOfPages,
    authorUri,
    popularBlogUris,
  },
}) => {
  const posts = data.recentWpPost.nodes
  const categories = data.allWpCategory.nodes

  const popularPost = data.allWpPost.nodes
    .map(node => ({
      uri: node.uri,
      title: node.title,
      featuredImage: node.featuredImage,
      rank: popularBlogUris.findIndex(uri => uri === node.uri),
    }))
    .filter(post => post.rank >= 0)
    .toSorted((a, b) => a.rank - b.rank)

  function pagination() {
    const items = []
    const pageCount = numberOfPages
    const marginPagesDisplayed = 1
    const pageRangeDisplayed = 5
    const selected = pageNumber
    const breakLabel = "..."

    if (pageCount <= pageRangeDisplayed) {
      let index
      let page

      for (index = 0; index < pageCount; index++) {
        page = index + 1
        items.push(
          <Number key={`pagination-link-${page}`} page={page} uri={authorUri} />
        )
      }
    } else {
      let leftSide = pageRangeDisplayed / 2
      let rightSide = pageRangeDisplayed - leftSide

      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected
        leftSide = pageRangeDisplayed - rightSide
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected
        rightSide = pageRangeDisplayed - leftSide
      }

      let index
      let page
      let breakView

      for (index = 0; index < pageCount; index++) {
        page = index + 1

        if (page <= marginPagesDisplayed) {
          items.push(
            <Number
              key={`pagination-link-${page}`}
              page={page}
              uri={authorUri}
            />
          )
          continue
        }

        if (page > pageCount - marginPagesDisplayed) {
          items.push(
            <Number
              key={`pagination-link-${page}`}
              page={page}
              uri={authorUri}
            />
          )
          continue
        }

        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(
            <Number
              key={`pagination-link-${page}`}
              page={page}
              uri={authorUri}
            />
          )
          continue
        }

        if (breakLabel && items[items.length - 1] !== breakView) {
          breakView = (
            <span key={`pagination-break`} className={styles.pagination__item}>
              {breakLabel}
            </span>
          )
          items.push(breakView)
        }
      }
    }

    return items
  }

  if (!posts.length) {
    return (
      <BlogLayout isArchivePage>
        <Head title="ブログ" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </BlogLayout>
    )
  }

  return (
    <BlogLayout isArchivePage>
      <Head title="ブログ" />

      <div className={styles.categoriesWrapper}>
        <div className={styles.categoriesInner}>
          <ul className={styles.categories}>
            <li className={styles.categories__item}>
              <Link
                to={`/blog/`}
                className={styles.categories__link}
                activeClassName="is-active"
              >
                最新記事
              </Link>
            </li>
            {categories
              .filter(category => category.parentId === null)
              .map(category => (
                <li key={category.id} className={styles.categories__item}>
                  <Link
                    to={`/blog${category.uri}`}
                    className={styles.categories__link}
                    activeClassName="is-active"
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className={styles.contents}>
        <main className={styles.main}>
          {posts.map(post => (
            <Link
              key={post.uri}
              to={`/blog${post.uri}`}
              className={styles.archive}
              itemScope
              itemType="http://schema.org/Article"
            >
              {post.featuredImage?.node?.localFile?.childImageSharp ? (
                <Img
                  fluid={
                    post.featuredImage?.node?.localFile?.childImageSharp?.fluid
                  }
                  className={styles.archive__img}
                  imgStyle={{
                    transform: "none",
                    transition: "transform 225ms ease",
                    willChange: "transform",
                  }}
                />
              ) : (
                <StaticImage
                  src="../images/common/noimg.png"
                  alt=""
                  width={300}
                  height={168}
                  className={styles.archive__img}
                  imgStyle={{
                    transform: "none",
                    transition: "transform 225ms ease",
                    willChange: "transform",
                  }}
                />
              )}
              <div className={styles.archive__col}>
                <div className={styles.archive__category}>
                  {post.categories.nodes[0].name}
                </div>
                <div className={styles.archive__date}>
                  <IconCalendar />
                  <time>{post.date}</time>
                </div>
                <h2 className={styles.archive__title} itemProp="headline">
                  {parse(post.title)}
                </h2>
              </div>
            </Link>
          ))}
          {!!pagination().length && (
            <div className={styles.pagination}>
              {previousPagePath && (
                <Link to={previousPagePath} className={styles.pagination__icon}>
                  <IconArrowLeft />
                </Link>
              )}
              {pagination()}
              {nextPagePath && (
                <Link to={nextPagePath} className={styles.pagination__icon}>
                  <IconArrowRight />
                </Link>
              )}
            </div>
          )}
        </main>
        <div className={styles.sidebar}>
          <a
            href="https://www.green-japan.com/company/7196"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="./images/recruit.png"
              alt="求ム！人財！意欲ある方、お問い合わせください！募集要項はコチラ"
              width={336}
              quality={100}
            />
          </a>
          <div className={styles.widget}>
            <h2 className={styles.widget__title}>人気の記事</h2>
            <div className={styles.widget__box}>
              <div className={styles.widget__list}>
                {popularPost.map(entry => (
                  <Link
                    key={entry.uri}
                    to={`/blog${entry.uri}`}
                    className={styles.card}
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    {entry.featuredImage?.node?.localFile?.childImageSharp ? (
                      <Img
                        fluid={
                          entry.featuredImage?.node?.localFile?.childImageSharp
                            ?.fluid
                        }
                        className={styles.card__img}
                      />
                    ) : (
                      <StaticImage
                        src="../images/common/noimg.png"
                        alt=""
                        width={150}
                        height={84}
                        className={styles.card__img}
                      />
                    )}
                    <p className={styles.card__title}>{entry.title}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlogLayout>
  )
}

export default BlogAuthorsArchive

export const pageQuery = graphql`
  query WordPressAuthorPostArchive(
    $offset: Int!
    $postsPerPage: Int!
    $authorName: String!
  ) {
    # カテゴリデータ
    allWpCategory {
      nodes {
        id
        name
        uri
        parentId
      }
    }

    # 全記事データ
    allWpPost {
      nodes {
        uri
        date(formatString: "YYYY.MM.DD")
        title
        excerpt
        categories {
          nodes {
            name
            id
          }
        }
        featuredImage {
          node {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 168) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    # 著者 10記事ごと
    recentWpPost: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: { author: { node: { name: { eq: $authorName } } } }
    ) {
      nodes {
        uri
        date(formatString: "YYYY.MM.DD")
        title
        excerpt
        categories {
          nodes {
            name
            id
          }
        }
        featuredImage {
          node {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 168) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
